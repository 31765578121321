import BgVideo from "../video/bgVideo-1.mp4";
import "../construction/construction.css";

function Construction() {
  return (
    <div className="container">
      <video autoPlay loop muted className="bgVideo">
        <source src={BgVideo} type="video/mp4" />
      </video>

      <div className="overlay">
        <div className="custom-text">
          <p>JUNGLE VIEW HOMES</p>
          <div className="text">(site under construction)</div>
        </div>
      </div>
    </div>
  );
}

export default Construction;
